import React ,{Component} from 'react';
import Sticky from './Sticky.js';
import Icon from './Icon.js';
import Logo from './Logo.js';
// import ReportText from '../img/troax-group-2020-white.svg';
import ReportTextDark from '../img/troax-group-2020.svg';
import { TroaxGroup, DownloadReport } from './TroaxTextSnippets.js';

// t = current time
// b = start value
// c = change in value
// d = duration
Math.easeInOutQuad = function (t, b, c, d) {
  t /= d/2;
  if (t < 1) return c/2*t*t + b;
  t--;
  return -c/2 * (t*(t-2) - 1) + b;
};

class Menu extends Component {
  constructor(props){
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleLinkClick = this.handleLinkClick.bind(this);
    this.scrollToId = this.scrollToId.bind(this);
    // console.log('props: ', props);

    this.state = {
      open:false
    }
  }

  handleClick(){
    let state = !this.state.open;
    if(state){
      document.documentElement.classList.add('menu-is-open');
    }
    else {
      document.documentElement.classList.remove('menu-is-open');
    }

    this.setState({
      open: state
    })
  }

  handleLinkClick(e){
    e.preventDefault();
    let target = e.currentTarget;
    let articleId = target.getAttribute('href').split("#")[1];

    this.scrollToId(articleId, 500, false)

    if(articleId === 'banner' && !this.state.open )return false;
    setTimeout(function () {
      this.handleClick();
    }.bind(this), 300);
  }

  // componentDidMount(){
  //   this.page = document.getElementById("page");
  //
  // }

  scrollToId(id, duration, changeHash) {
      changeHash=changeHash || false;
      var root = document.scrollingElement || document.documentElement;
      const scrollTo = document.getElementById(id).offsetTop - 100;

      var start = root.scrollTop,
          change = scrollTo - start,
          currentTime = 0,
          increment = 20;

      var animateScroll = function(){
          currentTime += increment;
          var val = Math.easeInOutQuad(currentTime, start, change, duration);
          root.scrollTop = val;
          if(currentTime < duration) {
              setTimeout(animateScroll, increment);
          }
          else {
            if(changeHash){
              window.location.hash = id;
            }
          }
      };
      animateScroll();
    }


  render(){
    // <a href="#banner" className="logo"><img src={Logo} alt=""/></a>
    // <a href="#banner" onClick={this.handleLinkClick} >Till toppen</a>
    let lang = this.props.lang !== "en" ? "en" : "sv";
    let langLink = this.props.lang !== "en" ? "/en" : "./";
    let _class = this.state.open ? "menu menu--open " : "menu ";
    _class += this.props.className;
    return (
      <Sticky>
      <div className={_class} >


        <a href="#banner" onClick={this.handleLinkClick} className="logo"><Logo /></a>
        <img className="hidden-mobile annual-as-text" src={ReportTextDark} alt=""/>
        

        <TroaxGroup year={this.props.year} className="hidden-mobile" />


        <nav>


          {
            this.props.links.map(function(textObj){
              return (
                <a onClick={this.handleLinkClick} key={textObj.id} href={"#"+textObj.id}>{textObj.title}</a>
              )
            }.bind(this))
          }
          <div className="hidden-mobile desc">
            {/* <img src={ReportText} alt=""/> */}
              <TroaxGroup year={this.props.year} />

            </div>

          <div className="hidden-mobile">
            <img src="../media/Troax_Stangsel.jpg" alt=""/>
          </div>

        </nav>
        <a href={langLink} className="btn-circle lang-sel">

          <span>{lang}</span>
        </a>
        {/* <div className="pdf-as-text hidden-mobile"  alt=""></div> */}
          <DownloadReport year={this.props.year} lang={this.props.lang} className="hidden-mobile" />
          <a href={"../media/"+this.props.pdfLink} target="download" className="btn-circle pdf">
          <span>PDF</span>
          <Icon name="download-line" color="" size={35}/>
        </a>
        <div className="menu-toggle" onClick={this.handleClick}>
          <Icon className={this.state.open ? "hidden": ""} name="menu-line" color="" size={35}/>
          <Icon className={!this.state.open ? "hidden": ""} name="close-line" color="" size={35}/>
        </div>
      </div>
      </Sticky>
    );
  }
}

export default Menu;
