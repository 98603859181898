import PropTypes from 'prop-types';

let Templates = {};

Templates['Banner'] = require('./templates/Banner.js').default;
Templates['Default'] = require('./templates/Default.js').default;
Templates['Brief'] = require('./templates/Brief.js').default;
Templates['BriefExtra'] = require('./templates/BriefExtra.js').default;
Templates['Numbers'] = require('./templates/Numbers.js').default;
Templates['Units'] = require('./templates/Units.js').default;
Templates['Video'] = require('./templates/Video.js').default;
Templates['CEO'] = require('./templates/CEO.js').default;
Templates['Mission'] = require('./templates/Mission.js').default;
Templates['Group'] = require('./templates/Group.js').default;
Templates['Areas'] = require('./templates/Areas.js').default;

Templates.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.number
};

export default Templates
