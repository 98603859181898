import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import './App.scss';
import Site from './Site.js';
import DataSwe from './Data.json';
import DataEng from './DataEn.json';

function App () {


    return (
      <Router>
        <Switch>
          <Route exact path="/">
            <Site data={DataSwe} lang={"se"} />
          </Route>
          <Route path="/">
            <Site data={DataEng} lang={"en"} />
          </Route>
        </Switch>
      </Router>
    )
}

export default App;
