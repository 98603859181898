import React from 'react';

import '../styles/Blob.scss';

const Blob = (props) => {

  return (

    <div className="blob">
      <div className="inner-blob"></div>
    </div>

  )
}

export default Blob;
