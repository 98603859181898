import React ,{Component} from 'react';
import ArticleFooter from '../components/ArticleFooter.js';
import Parallax from '../components/Parallax.js';
import ReactHtmlParser from 'react-html-parser';

import '../styles/areas.scss'

class Areas extends Component {
  constructor(props){
    super(props);
    this.classes = (this.props.details['classes']) || "";
  }

  render(){
    let title = this.props.content.title;
    let images = this.props.content.images;
    let count = 0;
    return (
      <article id={this.props.id} key={"article-"+this.props.id} className={this.classes}>

        <div className="inner">
          <header className="header">
            <h1 className="title--large">
              {ReactHtmlParser(title)}
            </h1>
          </header>
          <div className="images">
          {
            images.map((image)=>{
              count++;
              return (
                <figure className={image.classes} key={"images-"+count}>
                {
                  count === 1 &&
                  <Parallax speed={0.3} deltaY={0} deltaX={0}>
                    <img src={"../media/" + image.src} alt={image.alt || "Troax"}/>
                  </Parallax>

                }
                {
                  count === 2 &&
                  <Parallax speed={0.3} deltaY={.1} deltaX={0}>
                    <img src={"../media/" + image.src} alt={image.alt || "Troax"}/>
                  </Parallax>
                }
                {
                  count === 3 &&
                  <Parallax speed={0.3} deltaY={0} deltaX={0}>
                    <img src={"../media/" + image.src} alt={image.alt || "Troax"}/>
                  </Parallax>

                }

                </figure>
              )
            })
          }

          </div>
        </div>
        <ArticleFooter props={this.props}/>

      </article>
    )
  }
}

export default Areas;
