import React ,{Component, createRef} from 'react';
import ArticleFooter from '../components/ArticleFooter.js';
import ReactHtmlParser from 'react-html-parser';
import '../styles/units.scss';
import UnitsMap from '../UnitsMap/UnitsMap.js';

class Units extends Component {
  constructor(props){
    super(props);
    this.classes = (this.props.details['classes']) || "";
    this.ref = createRef();

    this.handleMoreClick = this.handleMoreClick.bind(this);
  }

  handleMoreClick(){
    const el = this.ref.current;

    el.scrollTo({
      left: el.offsetWidth,
      behavior: "smooth",
    })
  }

  render(){
    let units = this.props.content.units;

    return (
      <article id={this.props.id} key={"article-"+this.props.id} className={this.classes}>
        <div className="inner">

          <header className="header">
              <h1 className="title--medium">{this.props.content.title}</h1>
          </header>

          <div className="units-wrapper">
            <div className="flex units" ref={this.ref}>

              {
                Object.keys(units).map((key)=>{
                  return (
                    <div className="unit" key={key}>
                      <span className="city">{units[key].city},</span>
                      <span className="country">{units[key].country}</span>
                      <span className="desc">{units[key].desc}</span>
                      <ul className="numbers">
                        {
                          Object.keys(units[key].numbers).map((number)=>{
                            return (
                              <li key={number}>
                                <span className="highlight-desc">{units[key].numbers[number].title}</span>
                                <span className="highlight">{ReactHtmlParser(units[key].numbers[number].number)}</span>
                              </li>
                            );
                          })
                        }
                      </ul>
                      {units[key].text &&
                        <span className="extra">{units[key].text}</span>
                      }
                    </div>
                  )
                })
              }

            </div>
              <div className="units-more" onClick={this.handleMoreClick}></div>
          </div>

          <div className="production-units-map">
              {/* <img src={"../media/" + this.props.details.media} alt="Production map"/> */}
              <UnitsMap />
          </div>

      </div>

      <ArticleFooter props={this.props}/>

    </article>
    )
  }
}

export default Units;
