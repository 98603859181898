import React ,{Component} from 'react';
import FormatText from '../components/FormatText.js';
import ArticleFooter from '../components/ArticleFooter.js';
import Parallax from '../components/Parallax.js';

class CEO extends Component {
  constructor(props){
    super(props);
    this.classes = (this.props.details['classes']) || "";

  }

  render(){
    return (
      <article id={this.props.id} key={"article-"+this.props.id} className={this.classes}>
        <div className="inner space-y">

          <div className="cols ratio-2-1">
            <figure className="col">
            <Parallax speed={0.25} deltaY={-.15} deltaX={0}>
              <img src={"../media/" + this.props.details.media} alt=""/>
            </Parallax>
            </figure>
            <div className="col">
              <FormatText key={"test-" + this.props.id} data={this.props.content} />
            </div>

          </div>


        </div>
        <ArticleFooter props={this.props}/>

      </article>
    )
  }
}

export default CEO;
