import React ,{Component} from 'react';
import '../styles/Video.scss';
import Icon from '../components/Icon.js';
import WindowProps from '../components/WindowProps.js';
import PlayCircle from '../img/play_circle.svg';

class Video extends Component {
  constructor(props){
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.stopPlaying = this.stopPlaying.bind(this);
    this.onWheel = this.onWheel.bind(this);

    this.state = {
      playing: false,
      video: null,
      rotate:null
    }

    this.video = null;

    // window.addEventListener('scroll', this.onWheel);

  }

  componentDidMount(){
    window.addEventListener('scroll', this.onWheel);

  }

  componentWillUnmount(){
    window.removeEventListener('scroll', this.onWheel);

  }

  onWheel(e){
    this.setState({
      rotate: parseInt(WindowProps.SCROLLTOP/10)
    })
  }

  handleClick(){
    let el = document.getElementById(this.props.id);
    this.video = el.querySelector('video');

    this.video.addEventListener('ended', this.stopPlaying, false);
    this.video.addEventListener('pause', this.stopPlaying, false);
    this.setState({
      playing:true
    })
    setTimeout(function () {
      this.video.play();
      this.video.setAttribute('controls',true);
    }.bind(this), 500);
  }

  stopPlaying(){
    // console.log('stop playing');
    this.video.pause();

    this.setState({
      playing:false
    })
  }

  render(){
    let mediaSrc = this.props.details.media;
    let className = this.state.playing ? "video-wrapper playing" : "video-wrapper";

    let transform = {
      "transform": "rotate(" + this.state.rotate + "deg)"
    }

    return (
      <div id={this.props.id} className={className}>
        <video poster={"../media/" + this.props.details.poster}>
          <source src={"../media/" + mediaSrc} type="video/mp4" />
        </video>
        <div className="controls">
          <div className="play-btn" onClick={this.handleClick}>
            <img style={transform} className="play-btn__bg" src={PlayCircle} alt=""/>
            <Icon name="play-fill" />
          </div>
        </div>
      </div>
    )
  }
}
export default Video;
