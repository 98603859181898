import React from "react";
import ReactHtmlParser from 'react-html-parser';
import Logo from '../img/Troax_logo_White.svg';
import AnimateLetters from '../components/AnimateLetters.js';

import Icon from './Icon.js';

const Footer = (props) => {

  console.log('footer props', props);

    return (
      <footer className="main-footer scroll-point">

        <div className="inner">
        <AnimateLetters read count={1}>
          <h3 className="title--large">{props.tagLine}</h3>
        </AnimateLetters>
          <div className="download">

            <h4>{ ReactHtmlParser(props.text) }</h4>
            <a href={"../media/"+ props.pdfLink} className="btn-circle pdf">
              <span>PDF</span>
              <Icon name="download-line" color="#FFFFFF" size={35}/>
            </a>
          </div>
        </div>

        <div className="end">
          <a href="troax.com" className="logo"><img src={Logo} alt=""/></a>
        </div>

      </footer>
    );


}
export default Footer;
