import React ,{Component} from 'react';
import '../styles/Banner.scss';
// import Start from '../img/Stay_safe_2020.svg';
// import AnimatedSVG from '../components/AnimatedSVG';
import FormatText from '../components/FormatText';



function getScrollPos(){
  return (window.scrollY !== undefined) ? window.scrollY : document.documentElement.scrollTop;
}

class Banner extends Component {
  constructor(props){
    super(props);
    this.setEvents = this.setEvents.bind(this);
    this.onWheel = this.onWheel.bind(this);
    this.onResize = this.onResize.bind(this);
    this.updateBannerHeight = this.updateBannerHeight.bind(this);

    this.textRef = React.createRef();
    this.mainRef = React.createRef();
    this.state = {
      transform:0,
      scale:1,
      textWidth:0,
      bannerHeight:"200vh",
      inView: false
    }
  }
  setEvents(){
    window.addEventListener('WheelEvent', this.onWheel);
    window.addEventListener('syntheticWheel', this.onWheel);
    window.addEventListener('scroll', this.onWheel);
    window.addEventListener('resize', this.onResize);
  }
  componentDidMount(){
    this.setEvents();

    this.updateBannerHeight();
    let docBody = document.getElementsByTagName('body')[0];

    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
      docBody.classList.add('light-menu')
    } else {
      docBody.classList.remove('light-menu')
          
        }
      });
    }
  

    const observer = new IntersectionObserver(callback, {
      rootMargin: '0px',
      threshold: 0,
    });

    if(window.innerWidth > 768){
      observer.observe(this.mainRef.current);
    }

    return () => observer.disconnect();

  }

  updateBannerHeight(){
    setTimeout(function () {
      this.setState({
        bannerHeight: this.textRef.current.clientWidth - this.textRef.current.clientWidth/7
      })
    }.bind(this), 300);
  }

  onResize(){
    // console.log('resizing');
    this.updateBannerHeight();

  }

  onWheel(e){
    this.setState({
      transform: getScrollPos()/6,
      // scale: 1 + (getScrollPos()/20)/400,
      scale: 1 + (getScrollPos()/20)/900,
      shadow: getScrollPos()/10

    })
  }

  getMedia(){
    let mediaSrc = this.props.details.media;
    // let scale = (this.state.scale > 1.05) ? 1.05 : this.state.scale;
    // let transformImage = {
    //   "transform": "scale(" + scale + ") translate3d(0," + this.state.transform*3.5 + "px,0)"
    // }
    if(mediaSrc.match('.mp4')){
      return (
        <video autoPlay muted playsInline loop poster={"../media/" + this.props.details.poster}>
          <source src={"../media/" + mediaSrc} type="video/mp4" />
        </video>
      )
    }
    else {
      return (
        <img alt={this.props.content.title} src={"../media/" + mediaSrc} />
      )
    }

  }
  render(){

    let transform = {
      "transform": "translate3d(0, " + this.state.transform * .8 + "px, 0)"
    }

    let bannerStyles = {
      "height": this.state.bannerHeight
      // "height": "auto"
    }

    return (
      <div id={this.props.id} className="banner" style={bannerStyles} ref={this.mainRef}>
        <div className="banner__content">

          <div className="svg-text" ref={this.textRef} >
            {/* <AnimatedSVG /> */}
        {this.getMedia()}

          </div>
          <div style={transform} className="title-wrapper">
            <FormatText key={"banner-content"} data={this.props.header} />
          </div>
          {/* <FormatText key={"banner-content"} data={this.props.content} /> */}


        </div>

      </div>
    )
  }
}
export default Banner;
