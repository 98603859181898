import React, { Component } from 'react';

import '../styles/Sticky.scss';

// import WindowProps from './WindowProps.js'

function getScrollPos(){
  return (window.scrollY !== undefined) ? window.scrollY : document.documentElement.scrollTop;
}

class Sticky extends Component {

  constructor(props){
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
    this.myRef = React.createRef();
    this.setEvents = this.setEvents.bind(this);
    this.onWheel = this.onWheel.bind(this);
    this.SCROLLTOP = getScrollPos();;

  }

  init(){
    this.height = this.element.offsetHeight;
    document.documentElement.style.cssText = "padding-top: " + this.height + "px;";
    this.element.classList.add('sticky--stuck');
    this.setEvents();

  }

  setEvents(){
    window.addEventListener('wheel', this.onWheel);
    window.addEventListener('WheelEvent', this.onWheel);
    window.addEventListener('syntheticWheel', this.onWheel);
    window.addEventListener('mousewheel', this.onWheel);
    window.addEventListener('DOMMouseScroll', this.onWheel);
  }

  onWheel (e){
    this.SCROLLTOP = getScrollPos();;
    this.handleScroll(e);
  }

  componentDidMount(){
    this.element = this.myRef.current;

    setTimeout(function () {
      this.height = this.element.offsetHeight;
      document.querySelector('.nav-background').style.cssText = "height: " + this.height + "px;";
      this.init();

    }.bind(this), 500);

  }

  handleScroll(e, dist){
    var dir = e.deltaY;
    var scrollDist = (dist) ? {up:dist, down: (dist*-1)} : {down:20, up: -20};

    if(this.isSafari){

      if(dir > 0){
        this.element.classList.add('sticky--hide')
      }
      else if(dir < 0 || this.SCROLLTOP < this.height){
        this.element.classList.remove('sticky--hide')
      }
    }
    else {
      if(dir > scrollDist.down){
        this.element.classList.add('sticky--hide')
      }
      else if(dir < scrollDist.up || this.SCROLLTOP < this.height){
        this.element.classList.remove('sticky--hide')
      }
    }

  }

  render() {

    return (
      <this.props.children.type ref={this.myRef} className="sticky">

          {this.props.children}

      </this.props.children.type>

    );
  }
}

export default Sticky;

// EditArea.propTypes = {
//   text: React.PropTypes.string.isRequired
// }
