import React ,{Component} from 'react';
import FormatText from '../components/FormatText.js';
import ArticleFooter from '../components/ArticleFooter.js';
import Blob from '../components/Blob.js';

class Default extends Component {
  constructor(props){
    super(props);
    this.classes = (this.props.details['classes']) || "";

  }

  render(){
    return (
      <article id={this.props.id} key={"article-"+this.props.id} className={this.classes}>
        {
          this.props.details.blob &&
          <Blob />
        }
        <div className="inner">

          {
            this.props.header &&
            <header className="header">
              <FormatText data={this.props.header} />

            </header>
          }

          <FormatText key={"test-" + this.props.id} data={this.props.content} />

        </div>
        <ArticleFooter props={this.props}/>

      </article>
    )
  }
}

export default Default;
