import React, { Component } from 'react';
import '../styles/AnimateLetters.scss';


class AnimateLetters extends Component {
  constructor(props){
    super(props);
    this.words = this.props.children;
    this.delay = (this.props.delay) ? this.props.delay : 50;
    this.count = (this.props.count) ? this.props.count : "infinite";
    this.read = (this.props.read) ? true : false;
    this.myRef = React.createRef();

    this.baseClassName = "animate-letters";

    let _className = (this.read) ? this.baseClassName + " read" : this.baseClassName;

    this.state = {
      className: _className + " " + this.words.props.className
    }
  }

  render(){


    var letters = this.words.props.children.split('');
    let _className = this.state.className;
    return (
      React.createElement(this.words.type, {ref: this.myRef, className: _className},

        letters.map( function(letter, i) {
          let isBlank = (letter === " ")? true : false;
          var _className= isBlank ? 'letter--space' : 'letter';

          let _style = {
            animationDelay: (this.delay * i) + "ms",
            animationIterationCount: this.count
          }
          if(isBlank){
            return (
              <span key={letter + i} style={_style} className={_className}>{letter}</span>
            )
          }
          else{
            return (
              <span key={letter + i} style={_style} className={_className}>{letter}</span>
            )
          }
        }.bind(this))
      )
    )
  }
}

export default AnimateLetters;
