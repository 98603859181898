import React from 'react';

export const TroaxGroup = ({year="2020", className}) => {
    return (
        <div className={`troax-group ${className}`}>
            <span className="troax-group__text">Troax Group AB –</span>
            <span className="troax-group__year">{year}</span>
        </div>
    )   
}

export const DownloadReport = ({year="2020", lang="sv", className}) => {
    const text = {
        download: lang === "en" ? "Download" : "Ladda ner",
        report: lang === "en" ? "Annual Report" : "Årsredovisning"
    }

    return (
        <div className={`troax-download ${className}`}>
            <span className="troax-download__top">{text.download}</span>
            <span className="troax-download__text">{text.report}</span>
            <span className="troax-download__year">{year}</span>
        </div>
    )   
}