import React ,{Component} from 'react';
import FormatText from './FormatText.js';
import Icon from './Icon.js';
import '../App.scss';

class SingleArticle extends Component {
  constructor(props){
    super(props);

    this.handleClose = this.handleClose.bind(this);

    this.state = {
      loading:true
    }
  }

  shouldComponentUpdate(nextProps, nextState){
    // if (nextProps.data !== this.props.data ) {
    if (nextProps.data !== this.props.data || nextState.loading !== this.state.loading) {
      return true;
    }
    return false;
  }

  componentDidUpdate(prevProps, prevState){

    let loading = !prevState.loading;

      setTimeout(function () {
        this.setState({
          loading:loading
        })
      }.bind(this), 1000);

  }

  handleClose(){
      this.props.handleClose();
  }

  render(){
    if(!this.props.data){
      return false;
    }

    let articleData = Array.isArray(this.props.data) ? this.props.data : this.props.data.content ;
    let classes = this.props.data.props ? this.props.data.props.classes :"";
    return (

      <section loading={ this.state.loading ? "true" : "false" } className="page--single">
        <div className="single-article inner">
          <div className="btn-circle--close" onClick={this.handleClose}>
            <Icon name="close-line" color="#8c8179" size={25}/>
          </div>

          { this.props.data.link &&

            <a href={"../media/"+this.props.data.link.url} className="btn-circle pdf">
              <span>PDF</span>
              <Icon name="download-line" color="" size={35}/>
            </a>
          }

          <article className={classes}>

            {
              this.props.data.header &&
              <header className={"header " + classes}>
                <FormatText data={this.props.data.header} />
              </header>
            }

            <div className="text-content">
              <FormatText data={articleData} header={this.props.data.header? true :false} />
            </div>

          </article>

        </div>
      </section>

    );
  }
}

export default SingleArticle;
