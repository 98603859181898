import React from "react";

const Logo = () => (
  <svg version="1.1" className="logo" xmlns="http://www.w3.org/2000/svg" x="0" y="0"
  	 viewBox="246.3 147.2 131.4 45" enableBackground="new 246.3 147.2 131.4 45" >
  <g>
  	<path fill="#005095" d="M375.6,172.6c1.2,0,2.1-0.9,2.1-2.1c0-1.2-1-2.1-2.1-2.1c-1.2,0-2.2,0.9-2.2,2.1
  		C373.4,171.6,374.4,172.6,375.6,172.6L375.6,172.6z M373.8,170.5c0-1,0.8-1.8,1.7-1.8c1,0,1.8,0.8,1.8,1.8c0,1-0.8,1.7-1.8,1.8
  		C374.6,172.2,373.8,171.5,373.8,170.5L373.8,170.5z M375.1,170.6h0.4l0.6,1.1h0.4l-0.7-1.1c0.4,0,0.6-0.2,0.6-0.7
  		c0-0.5-0.3-0.7-0.9-0.7h-0.9v2.4h0.4L375.1,170.6L375.1,170.6z M375.1,170.3v-0.8h0.5c0.3,0,0.5,0.1,0.5,0.4c0,0.4-0.3,0.4-0.6,0.4
  		H375.1L375.1,170.3z"/>
  	<polygon fill="#005095" points="263,166.1 271.2,166.1 272.6,162.8 247.9,162.8 246.3,166.1 254.8,166.1 249,177.7 257.1,177.7
  		"/>
  	<polygon fill="#005095" points="377.6,162.7 369.4,162.7 363.7,166.4 360.6,162.7 351.6,162.7 357.6,170.4 346.4,177.6
  		355.2,177.6 360.6,174.1 363.3,177.6 372.3,177.6 366.6,170.2 	"/>
  	<polygon fill="#0095DB" points="246.5,147.2 377.7,147.2 377.7,155.6 246.5,155.6 	"/>
  	<polygon fill="#0095DB" points="246.5,183.9 377.7,183.9 377.7,192.2 246.5,192.2 	"/>
  	<path fill="#005095" d="M307.9,177.9c7.5-0.1,13,0.9,15.4-5.6l1.9-4.3c2-4.7-1.8-5.4-11.8-5.3c-7.6,0-10.6-0.7-14,5.6l-2,4
  		C295.1,178.5,300.3,178,307.9,177.9L307.9,177.9z M309,174.7c-3.7,0-4.2-0.3-3.4-2.7l2-3.6c1-2.3,2.3-2.6,6-2.6
  		c3.7,0,4.1,0.6,3.2,3l-1.3,3.2C314.6,174.3,312.7,174.7,309,174.7L309,174.7z"/>
  	<path fill="#005095" d="M279.7,169.4l1.6-3.3h5.7c2.5-0.1,3,0.4,2.6,1.7c-0.5,1.3-1.3,1.7-4,1.6L279.7,169.4L279.7,169.4z
  		 M267.1,177.7h7.9l2.5-5.1h6.4c3.2-0.1,2.9,0.7,2.1,2.1l-1.2,2.9h8.6l1.1-3.1c0.2-0.5,0.3-0.9,0.3-1.3c-0.3-0.8-0.6-0.7-1.2-1.3
  		c-0.3-0.2-0.2-0.6,0.4-0.8c2.9-0.8,3.4-2.3,4-4.3c1-3.6-2.6-4-9.1-4.1h-15.2L267.1,177.7L267.1,177.7z"/>
  	<path fill="#005095" d="M341.3,175l0.4,2.6h8.1l-3-14.9h-10.1l-15.5,15h6.9l2.5-2.6L341.3,175L341.3,175z M340.8,171.4h-6.4
  		l5.6-5.6L340.8,171.4L340.8,171.4z"/>
  </g>
  </svg>
);

export default Logo;
