import React ,{Component} from 'react';
import './App.scss';
import Menu from './components/Menu.js';
import Templates from './Templates.js';
import Footer from './components/Footer.js';
import SingleArticle from './components/SingleArticle.js';
import ScrollPoints from './components/ScrollPoints.js';

class App extends Component {
  constructor(props){
    super(props);

    this.state = {
      singlePageOpen:false,
      activeId:false,
      loading:true
    }

    this.Data = this.props.data;

    this.openSingle = this.openSingle.bind(this);
    this.closeArticle = this.closeArticle.bind(this);
    this.getArticleById = this.getArticleById.bind(this);
    this.activeId = false;

  }

  componentDidMount(){
    // ScrollPoints.init();
    const hasHash = window ? window.location.hash : (document.window ? document.window.location.hash : false);
    
    ScrollPoints(['.scroll-point']);
    setTimeout(function () {
      this.setState({
        loading:false
      })
      if (hasHash) {
        const hashId = document.getElementById(hasHash.replace('#', ''));
        window.scrollTo(0, hashId.offsetTop);
      }
    }.bind(this), 500);

  }

  componentDidUpdate(){
    // console.log('did update');
    if(this.state.singlePageOpen){
      document.body.classList.add('lock');
    }
    else {
      document.body.classList.remove('lock');

    }
  }

  toggleMenu(id){
    // console.log('menyn', id);
  }

  closeArticle(){
    this.setState({
      activeId: false,
      singlePageOpen: false,
    })

    setTimeout(function () {
      this.setState({
        activeArticle: false
      })
    }.bind(this), 600);
  }

  openSingle(e){
      let target = e.currentTarget;
      let openThisId = e.currentTarget.getAttribute('article-id') || false;
      let activeId = openThisId || false;
      let state;

      if(!openThisId){
        state = false;

        // return false;
      }
      else {
        this.activeId = openThisId;
        state = !this.state.singlePageOpen;
      }

      this.setState({
        articleIsLoading: true,
        activeId: activeId,

        activeArticle: this.getArticleById()
      })

      target.classList.add('animate');

      setTimeout(function () {
        this.setState({
          singlePageOpen: state
        })
      }.bind(this), 500);

      setTimeout(function () {
        target.classList.remove('animate');
      }, 700);

  }

  getArticleById(){
    let article = this.Data.articles[this.activeId];
    let content = article.more || article.content.more;
    // console.log('getArticleById', content);

    if(!content.title){
      content.title = this.Data.articles[this.activeId].props.menuTitle || false;
      if(!content.title){
        content.title = this.Data.articles[this.activeId].content.title || this.Data.articles[this.activeId].header[0].content;
      }
    }
    return content;
  }

  render(){

    const links = [];

    Object.keys(this.Data.articles).map((key)=>{
      if(this.Data.articles[key].props.hideInMenu)return false;
      let title = this.Data.articles[key].props.menuTitle || false;
      if(!title ){
        title = this.Data.articles[key].content.title || this.Data.articles[key].header[0].content;
      }

      let link = {
        title: title,
        id: key
      }
      links.push(link);
      return true;
    })

    // let header = this.Data.articles[this.state.activeId] ? this.Data.articles[this.state.activeId].header : false;
    const year = this.Data.pageInfo.year;
    let attr = this.Data.pageInfo.classes;
    let pdfLink = this.Data.pageInfo.pdfLink;
    let footerText = this.Data.pageInfo.footerText;
    let footerTextLarge = this.Data.pageInfo.footerTagLine;

    return (
      <div id="page" data-attr={attr} className={this.state.loading ? "loading wrapper" : " wrapper"}>

        <Menu lang={this.props.lang} year={year} handleClick={this.toggleMenu} pdfLink={pdfLink} className={this.state.singlePageOpen ? "hide":""} links={links}/>
        <div className={this.state.singlePageOpen ? "hidden nav-background" : "nav-background"}></div>

        <div className={this.state.singlePageOpen ? "page-wrapper single-page-open" : "page-wrapper"}>
          <main className="page">
            {
              Object.keys(this.Data.articles).map((key)=>{
                let obj = this.Data.articles[key];
                let type = obj.template;
                const Template = Templates[type];

                let eventOnClick = (obj.more || obj.content.more) ? this.openSingle : false;

                return <Template
                          key={"template-key-"+key}
                          id={key}
                          header={obj.header}
                          content={obj.content}
                          more={obj.more}
                          details={obj.props}
                          handleClick={eventOnClick}
                        />;
              })
            }


            <Footer pdfLink={pdfLink} text={footerText} tagLine={footerTextLarge} />
          </main>

          <SingleArticle
            key={"s-a"}
            data={this.state.activeArticle}
            id={this.state.activeId}
            handleClose={this.closeArticle}
          />

        </div>

      </div>
    );
  }
}

export default App;
