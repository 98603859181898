import React ,{Component} from 'react';
import FormatText from '../components/FormatText.js';
import ReactHtmlParser from 'react-html-parser';
import CountUp from 'react-countup';

import '../styles/Brief.scss';


class Brief extends Component {
  constructor(props){
    super(props);
    this.highlight = this.highlight.bind(this);
    this.classes = (this.props.details['classes']) || "";
    this.ref = React.createRef();
    this.state = {
      isVisible:false
    }

    this.animated = false;
  }

  componentDidMount(){

    let observer = new MutationObserver(function (event) {
      // console.log(event);
      if( event[0].target.className.match('scroll-point--active') && !this.animated ){
        let triggers = document.querySelectorAll('.trigger-on-scroll');

        for (const button of triggers) {
          button.click();
        }
          this.animated = true;

      }
    }.bind(this));

    setTimeout(function () {

      observer.observe(this.ref.current, {
        attributes: true,
        attributeFilter: ['class']
      })
    }.bind(this), 1000);

  }
  componentDidUpdate(){
    // console.log('updated', this.ref.current.className);
  }

  highlight(){
    // console.log('highlight');
    // this.ref.current.classList.add('highlight');
  }

  render(){
    let delay;
    return (
      <article offset="20%" ref={this.ref} id={this.props.id} key={"article-"+this.props.id} className={this.classes}>
        <div className="inner">

          <header className="header">
            <FormatText key={"brief-"+this.props.id} data={this.props.header} />
          </header>

          <ul className="numbers">

          {

            this.props.content.numbers &&
            this.props.content.numbers.map((obj,i)=>{
              let sign = obj.sales.substr(0,1);
              let num;
              if(sign === "+" || sign === "-"){
                num = parseFloat(obj.sales.split(sign)[1].split('%')[0]);
              }
              else {
                sign = "+/-";
                num = parseFloat(obj.sales.substr(0,obj.sales.length).split('%')[0]);
              }
              // console.log('sign: ', sign);
              delay = (i===0) ? 1 : delay + i/5;
              delay=1;
              let decimal = num % 1 ? 1 : 0;

              return(
                <li key={"brief-"+i} className="brief">
                <span className="highlight">

                  <CountUp start={0} end={num} delay={delay} suffix="%" duration={3} decimals={decimal} onStart={this.highlight} decimal=".">
                  {({ countUpRef, start }) => (
                      <div>{sign}
                        <span ref={countUpRef} />
                        <button className="trigger-on-scroll hidden" onClick={start}>Start</button>
                      </div>
                    )}
                  </CountUp>
                </span>

                  <h3 className="title">{obj.title}</h3>
                  <span className="end">{ReactHtmlParser(obj.end)}</span>
                </li>
              )
            })
          }
          </ul>

        </div>


      </article>
    )
  }
}

export default Brief;
