import React from "react";

import Icon from './Icon.js';

const ArticleFooter = (props) => {
  props = props.props;

  function handleClick(e){
      props.handleClick(e);
  }

  if((props.content && props.content.more) || props.more){
    return (
      <div className="article-footer">
        <div className="inner">
          <div className="btn-circle--toggle" onClick={handleClick} article-id={props.id}>
            <Icon name="add-line" color="#8c8179" size={25}/>
          </div>
        </div>

      </div>
    );
  }
  else {
    return false;
  }

}
export default ArticleFooter;
