let Diagrams = {};

   Diagrams['profit'] = require('./Diagram_sales_per_region_2023.js').default;
   Diagrams['profit_swe'] = require('./Diagram_sales_per_region_2023.js').default;
   Diagrams['sales'] = require('./Diagram_sales_2023.js').default;
   Diagrams['sales_swe'] = require('./Diagram_sales_2023.js').default;
   Diagrams['staple1'] = require('./diagrams/staple1_2023').default;
   Diagrams['staple2'] = require('./diagrams/staple2_2023').default;

   export default Diagrams
