import React, { Component } from 'react';

import '../styles/Parallax.scss';

import WindowProps from './WindowProps.js';
import InView from './InView.js';



class Parallax extends Component {

  constructor(props){
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
    this.getStyles = this.getStyles.bind(this);
    this.ref = React.createRef();

    this.bodyRect = document.body.getBoundingClientRect();
    // this.rect = this.element.getBoundingClientRect();

    let offset = this.props.offset || 0;
    let opacity = this.props.fade ? 0 : 1;

    this.state = {
      styles: {
        transform: 'translate3d(0, ' + offset + 'px, 0)',
        opacity: opacity
      }
    }

    this.type = this.props.children.type;
    this.className = this.props.className || "";
  }

  componentDidMount(){

    this.element = this.ref.current;
    let singlePageOpen = document.querySelector('.page--single') || false;

    if(this.element){
      this.bodyRect = singlePageOpen ? singlePageOpen.getBoundingClientRect() : document.body.getBoundingClientRect();
      this.rect = this.element.getBoundingClientRect();
      WindowProps.updateSize();
    }

    if(singlePageOpen){
      singlePageOpen.addEventListener('scroll', this.handleScroll)

    }
    else {
      window.addEventListener('scroll', this.handleScroll)
    }
  }

  componentWillUnmount(){

    window.removeEventListener('scroll', this.handleScroll)
    setTimeout(function () {
      this.setState({
        styles: this.getStyles()
      })
    }.bind(this), 1000);
  }

  handleScroll(e, dist){

    WindowProps.handleScroll();
    let scrollOffset = this.props.scrollOffset || 0;


    if( InView(this.element, scrollOffset) ){
      this.setState({
        styles: this.getStyles()
      })
    }
  }

  getStyles(){

    // var bodyRect = document.body.getBoundingClientRect();

    let dY = this.props.deltaY || 0;
    let dX = this.props.deltaX || 0;
    let offset = this.props.offset || 0;
    let scrollOffset = this.props.scrollOffset || 0;
    let fade = this.props.fade || null;
    let opacity = 1;

    const elementTop = this.rect.y - this.bodyRect.y + offset;

    let differenceY = WindowProps.SCROLLTOP + WindowProps.SIZE.height - scrollOffset - elementTop ;
// console.log('WindowProps.SCROLLTOP: ' + WindowProps.SCROLLTOP);
// console.log('WindowProps.SIZE.height: ' + WindowProps.SIZE.height);
// console.log('scrollOffset: ' + scrollOffset);
// console.log('elementTop: ' + elementTop);
// console.log('differenceY: ' + differenceY);
// console.log('- - - - - - - - - - - - - - - - - -');

    if(fade){
      let fadeValue = differenceY/200;
      if(fade){
        opacity = (fadeValue> 1) ? 1 : fadeValue ;
      }
    }

    let translate = {
      y: (differenceY - offset) * this.props.speed * dY,
      x: (differenceY - offset) * this.props.speed * dX
    };

    if(offset){
      // console.log('BEFORE: ' + translate.y);
      // translate.y += offset;
      translate.y = (translate.y > 0) ? translate.y : 0;
      // console.log('AFTER: ' + translate.y);

    }

    let styles= {
      transform: 'translate3d(' + translate.x + 'px, ' + translate.y + 'px, 0)',
      opacity: opacity
    }

    return styles
  }

  getElement(){
    let src = this.props.children.props.src || null;
    let alt = this.props.children.props.alt || null;
    let children = (src) ? null : this.props.children;
    let elProps = {
      ref: this.ref,
      src: src,
      alt: alt,
      style: this.state.styles,
      className: (this.props.fade)? this.className + " parallax parallax--fade": this.className + " parallax"
    }
    return (
      React.createElement(this.props.children.type, elProps, children)
    )
  }

  render() {

    return (
      this.getElement()
    );

  }
}

export default Parallax;
