import React ,{Component} from 'react';

import ArticleFooter from '../components/ArticleFooter.js';
import ReactHtmlParser from 'react-html-parser';
import Diagrams from '../Diagrams.js';

class Numbers extends Component {
  constructor(props){
    super(props);
    this.classes = (this.props.details['classes']) || "";
  }

  renderDiagrams(){
    return (
      <div className="diagrams columns">
      {
        this.props.content.diagrams.map((i)=>{
          const DiagramToRender = Diagrams[i];

          return (
            <figure key={i}>
              {/* <img src={"../media/" + i} alt="diagram"/> */}
              <DiagramToRender/>

            </figure>
          )
        })
      }
      </div>
    )
  }

  render(){
    return (
      <article id={this.props.id} key={"article-"+this.props.id} className={"diagrams-numbers " + this.classes}>
        <div className="inner">

          <header>
            <h3>{this.props.content.title} <span>EUR million</span> </h3>
          </header>

          <ul className="numbers">
          {

            this.props.content.numbers &&
            this.props.content.numbers.map((obj,i)=>{
              return(
                <li key={"circle-"+i} className="circle">
                  <h3 className="title">{ReactHtmlParser(obj.title)}</h3>
                  <span className="highlight">{ReactHtmlParser(obj.sales)}</span>
                  <span className="end">{ReactHtmlParser(obj.end)}</span>
                </li>
              )
            })
          }
          </ul>
          { ReactHtmlParser(this.props.content.text) }
          {
            this.props.content.diagrams &&
            this.renderDiagrams()

          }

        </div>

        <ArticleFooter props={this.props}/>

      </article>
    )
  }
}

export default Numbers;
