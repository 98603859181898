import React ,{Component} from 'react';
import ArticleFooter from '../components/ArticleFooter.js';
import ReactHtmlParser from 'react-html-parser';

class Group extends Component {
  constructor(props){
    super(props);
    this.classes = (this.props.details['classes']) || "";
  }

  render(){
    let title = this.props.content.title;
    return (
      <article id={this.props.id} key={"article-"+this.props.id} className={this.classes}>

        <div className="inner">
          <h1 className="title--group color-blue--light">
            {ReactHtmlParser(title)}
          </h1>
        </div>
        <ArticleFooter props={this.props}/>

      </article>
    )
  }
}

export default Group;
